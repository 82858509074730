console.log('3DHandler 2 geladen');


// Globale variablen
var canvas = document.getElementById('canvasModel');
var fader = document.getElementById('fader');
var scene = new THREE.Scene();
var camera = new THREE.PerspectiveCamera(16, canvas.clientWidth / canvas.clientHeight, 0.1, 1000);
var renderer = new THREE.WebGLRenderer({ alpha: true });
//var pointLight = new THREE.PointLight( 0xffffff, 0.9);
var pointLight = new THREE.SpotLight( 0xffffff, 0.9, 1000);
var dayLight = new THREE.AmbientLight( 0x444444, 0.8 );
var mouseState = 'up';
var touchState = 'up';
var lastValueFader;
var lastMousePosX;
var lastMousePosY;
var lastTouchPosX;
var object;

// Globale Events
canvas.addEventListener('mousedown', onMouseClick);
canvas.addEventListener('mouseup', endMouseClick);
canvas.addEventListener('mousemove', onMouseMoveHandler);
canvas.addEventListener('touchstart', onTouchDown, false);
canvas.addEventListener('touchend', endTouchEvent );
canvas.addEventListener('touchmove', onTouchMoveHandler, false);
fader.addEventListener('input' , faderValue);
fader.addEventListener('mouseup', endMouseClick );
renderer.setSize( canvas.clientWidth, canvas.clientHeight );
canvas.appendChild(renderer.domElement);

camera.position.z = 0.6;
camera.position.y = -0.10;
camera.rotation.y = -0.1;
camera.position.x = 0.01;
camera.rotation.x = 0.24;

pointLight.position.z = 0.5;
pointLight.position.y = -0.08;
pointLight.position.x = 0.24;

var loader = new THREE.ColladaLoader();
loader.load("./assets/modell/model.dae", function (result) {

    loader.options.convertUpAxis = false;
    
    object  = result.scene;
    object.updateMatrix();
    object.position.set( 0 ,0 ,0 );
    object.__dirtyPosition = true;
    object.rotation.set( -1.1 , 0, -0.9 );          // <-- Der erste Wert für die Drehung der Dose
    object.__dirtyPosition = true;
    scene.add( object );
    scene.add( dayLight );
    
    renderer.shadowMap.enable = true;
    renderer.shadowMap.type = THREE.PCFShadowMap;
    pointLight.target = object;

    pointLight.castShadow = true;
    pointLight.shadow = new THREE.LightShadow(new THREE.PerspectiveCamera(100, canvas.clientWidth / canvas.clientHeight, 0.1, 1000));
    pointLight.shadow.bias = 0.01;
    pointLight.shadow.mapSize.width =  2048 * 2;
    pointLight.shadow.mapSize.height = 2048 * 2;
    
    scene.add( pointLight );

    object.castShadow = true;


    render();
});

function render() {
    
    // Return Position Dose auf NULL Animation
    if ( mouseState == 'up' ){
        if ( object.rotation.x > -1.09 ){
            object.rotation.x -= 0.01;
            fader.value = 0;
        }else if ( object.rotation.x < -1.11 ){
            object.rotation.x += 0.01;
            fader.value = 0;
        }else if ( object.rotation.x == -1.1 ){
            object.rotation.x = -1.1;
        };
    };
    requestAnimationFrame(render);
    renderer.render(scene, camera);
};

function onTouchDown( event ){

    var mc = new Hammer(canvas);
    mc.on('panleft panright' , function(ev){

        touchState = 'down';
        lastTouchPosX = ev.deltaX;
    });
};

function onMouseClick ( event ){

    mouseState = 'down';
    lastMousePosX = event.clientX;
    lastMousePosY = event.clientY;
};

function endMouseClick ( event ){

    mouseState = 'up';
};

function endTouchEvent (){

    touchState = 'up';
};

function onTouchMoveHandler ( event ){

    if (  touchState == 'down'){

        //grobe Touch Animation
        object.rotation.z += lastTouchPosX/2000;
    };
};

function onMouseMoveHandler ( event ) {
    
    if ( mouseState == 'down' ){
        var deltaX =  event.clientX - lastMousePosX;
        var deltaY = event.clientY - lastMousePosY;
        
        object.rotation.z += deltaX/100;
        lastMousePosX = event.clientX;
        lastMousePosY = event.clientY;
    };
};

function faderValue ( event ){

    mouseState = 'down';
    var FaderValue  = document.getElementById('fader').value;
    
    if ( isNaN(lastValueFader) || lastValueFader == 0 ){
        lastValueFader = 0;
    };

    if ( mouseState == 'down' ){

        var deltaX;
        var deltaY = event.clientY - lastMousePosY;

        // Weichenstellung Animation Dose Vertikal über Input Range
        if ( lastValueFader < parseInt(FaderValue) ){
            //bewegung nach oben
            if ( FaderValue < 0 ){
                deltaX = object.rotation.x - FaderValue;
            }else{
                deltaX = FaderValue - object.rotation.x;
            };
        }else{
            //bewegun nach unten
            if ( FaderValue < 0 ){
                deltaX = FaderValue - object.rotation.x;
            }else{
                deltaX =  object.rotation.x - FaderValue;
            };
        };
        object.rotation.x += deltaX/100;
        
        // Set Last Value Fader
        if ( FaderValue < 0 ){
            lastValueFader =   parseInt(lastValueFader) + parseInt(FaderValue) + 1 ;
        }else{
            lastValueFader  =  parseInt(FaderValue);
        };
    };
    lastValueFader = FaderValue;
};